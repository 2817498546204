<template>
  <div data-cy="clustersStatsData">
    <div class="table-wrap table-wrap__unoverflow table-wrap-stat">
      <o-table
        backend-sorting
        class="statistic-table stats-custom-table table__overflow-auto main-table-wrap"
        :class="{ 'o-table-has-pagination': stats.length && totalPages > 1 }"
        :data="stats"
        :default-sort="[sortField, sortOrder]"
        :default-sort-direction="defaultSortOrder"
        hoverable
        :loading="loading"
        :mobile-cards="false"
        sort-icon="chevron-up"
        @sort="onSort"
      >
        <template v-for="column in columns">
          <o-table-column
            :key="column.id"
            v-bind="column"
          >
            <template
              v-if="column.searchable"
              slot="searchable"
              slot-scope="props"
            >
              <gl-input
                v-if="column.field === 'owner'"
                v-model="formData.owner"
                clearable
                :height="40"
                placeholder="Search by Owner"
                @clear="clearEntity"
                @enter="applyFilters"
              />
              <vSelect
                v-if="column.field === 'type'"
                v-model="formData.type"
                class="stat-select stat-select__address mr-1"
                :filterable="false"
                label="name"
                :options="typesOptions"
                placeholder="All types"
                @clear="clearType"
                @search="searchType"
              />
              <gl-input
                v-if="column.field === 'tags'"
                v-model="formData.tag"
                clearable
                :height="40"
                placeholder="Search by type tag"
                @clear="clearTag"
                @enter="applyFilters"
              />
              <div
                v-if="column.field === 'type.score'"
                class="flex align-center"
              >
                <gl-input
                  v-model="formData.from"
                  v-mask="'###'"
                  class="mr-1"
                  :height="40"
                  min="0"
                  name="score"
                  placeholder="From"
                  style="max-width: 80px"
                  type="number"
                  @enter="applyFilters"
                  @input="handleScoring(formData.from, 'from')"
                />
                -
                <gl-input
                  v-model="formData.to"
                  v-mask="'###'"
                  class="ml-1"
                  :height="40"
                  min="0"
                  name="score"
                  placeholder="To"
                  style="max-width: 80px"
                  type="number"
                  @enter="applyFilters"
                  @input="handleScoring(formData.to, 'to')"
                />
              </div>
              <div
                v-if="column.field === 'addressCount'"
                class="flex relative"
              >
                <vSelect
                  v-model="formData.addresses.more"
                  class="stat-select stat-select__address mr-1"
                  :clearable="false"
                  :filterable="false"
                  :options="addressNumberOptions"
                  :searchable="false"
                />
                <gl-input
                  v-model="formData.addresses.size"
                  v-mask="['#','##', '####', '#,###', '##,###', '###,###', '###,####',
                           '#,###,###', '##,###,###', '###,###,###']"
                  clearable
                  :disabled="formData.addresses.more.value === defAddrOrder"
                  :height="40"
                  placeholder="# of Addresses"
                  @clear="clearSize"
                  @enter="applyFilters"
                />
              </div>
              <div
                v-if="column.field === 'actions'"
                class="flex relative"
              >
                <div class="actions flex align-center">
                  <gl-menu-item
                    v-tooltip.top="'Clear all filters'"
                    class="mr-3"
                    :disabled="loading"
                    fullwidth
                    icon="reset-action"
                    :icon-height="24"
                    :icon-width="24"
                    @click="resetFilters"
                  />
                  <button
                    v-tooltip.top="'Apply filters'"
                    class="gl-button gl-button--dark gl-button--padder filters-action-btn"
                    :disabled="loading"
                    @click="applyFilters"
                  >
                    go
                  </button>
                </div>
              </div>
            </template>
            <template v-slot:header>
              <div class="flex align-center">
                <div>
                  {{ column.label }}
                </div>
                <gl-icon
                  v-if="column.sortable"
                  class="ml-1"
                  :height="24"
                  name="add-sorting"
                  :width="24"
                />
              </div>
            </template>
            <template v-slot="props">
              <div v-if="column.field === 'riskScore'">
                <span
                  class="capitalize"
                  :class="{ 'warning-text': props.row[column.field]}"
                >
                  {{ props.row[column.field] }}
                </span>
              </div>
              <div v-else-if="column.field === 'cluster'">
                <div
                  v-tooltip.top="'Сlick to copy'"
                  class="pointer min-w-80"
                  @click="copy(props.row.cluster)"
                >
                  {{ props.row.cluster ? trancateString(String(props.row.cluster)) : '-' }}
                </div>
              </div>
              <div v-else-if="column.field === 'type'">
                <GlTag
                  v-for="(type, index) in props.row.type"
                  :key="index"
                  class="mr-1 mb-1"
                  :score="type.score"
                  :tag="type.name"
                  :value="type._id"
                  @remove="removeTag(type, props.row.address)"
                />
              </div>
              <div v-else-if="column.field === 'type.score'">
                <span
                  v-for="(type, index) in props.row.type"
                  :key="index"
                >
                  {{ type.score }}
                </span>
              </div>
              <div v-else-if="column.field === 'actions'" />
              <div
                v-else-if="column.field === 'tags'"
                class="min-w-200"
              >
                <GlTag
                  v-for="(tag, index) in props.row.tags"
                  :key="index"
                  class="mr-1 mb-1"
                  :score="tag.score"
                  :tag="tag.name"
                  :value="tag._id"
                  @remove="removeTag(tag, props.row.address)"
                />
              </div>
              <div
                v-else-if="column.field === 'addressCount'"
                class="min-w-350"
              >
                {{ props.row[column.field] ? toComaSeparate(props.row[column.field]) : '-' }}
              </div>
              <div v-else>
                {{ props.row[column.field] ? props.row[column.field] : '-' }}
              </div>
            </template>
          </o-table-column>
        </template>
        <template #empty>
          <div
            v-if="loading"
            class="flex align-center justify-center"
          >
            <gl-loader />
          </div>
          <div
            v-else
            class="empty-users-data flex column align-center"
          >
            <gl-icon
              :height="24"
              name="statistic"
              :width="24"
            />
            No data here yet
          </div>
        </template>
      </o-table>
      <div class="flex space-between pa-2">
        <div class="flex align-center pa-3 m-column m-pa-0 m-pt-2">
          <div class="mr-2 fs-14 bold m-fs-12 m-mb-3">
            Rows per page:
          </div>
          <vSelect
            v-model="perPage"
            class="stat-select__pagination mr-1"
            :clearable="false"
            :options="pagesOptions"
            :searchable="false"
            @input="countChange"
          />
        </div>
        <o-pagination
          v-if="stats.length && totalPages > 1"
          class="stat-pagination"
          :current.sync="currentPage"
          order="right"
          :per-page="perPage"
          simple
          :total="total"
          @change="pageChange"
        >
          <o-pagination-button
            slot="previous"
            slot-scope="props"
            :page="props.page"
          >
            <gl-menu-item
              class="change-page mr-2"
              :disabled="props.page.disabled"
              icon="left"
              :icon-height="24"
              :icon-width="24"
              label=""
              not-outline
            />
          </o-pagination-button>

          <o-pagination-button
            slot="next"
            slot-scope="props"
            :page="props.page"
          >
            <gl-menu-item
              class="change-page"
              :disabled="props.page.disabled"
              icon="right"
              :icon-height="24"
              :icon-width="24"
              label=""
              not-outline
            />
          </o-pagination-button>
        </o-pagination>
      </div>
    </div>
    <DeleteTagModal
      v-if="showDeleteModal"
      v-model="showDeleteModal"
      :tag="removedTag"
      @close="showDeleteModal = false"
      @submit="deleteTag"
    />
  </div>
</template>

<script>
// Utils
import { toComaSeparate, toNumber } from "@/utils/formatNumber";
// Vuex
import { mapActions, mapState } from 'vuex';
// Components
import GlMenuItem from '@/components/gl-menu-item'
import GlIcon from '@/components/gl-icon'
import GlTag from '@/components/gl-tag'
import DeleteTagModal from '../../tagging/modals/DeleteTagModal'
import GlInput from '@/components/gl-input'
import GlLoader from '@/components/gl-loader'
import vSelect from 'vue-select'
// Models
import filtersModel from '../models/clusterFiltersModel'
import _ from "lodash";
// Static
import { riskOptions, addressNumberOptions, pagesOptions } from '../static/filtersLabelsData'
// Utils
import { trancateString } from "@/utils/text-formatter";

export default {
  components: {
    GlMenuItem,
    GlIcon,
    GlLoader,
    GlTag,
    DeleteTagModal,
    vSelect,
    GlInput,
  },
  props: {
    activeTab: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      pagesOptions: pagesOptions,
      formData: filtersModel(),
      oldData: null,
      showDeleteModal: false,
      typesOptions: [],
      columns: [
        {
          field: 'cluster',
          label: 'Cluster ID',
        },
        {
          field: 'owner',
          label: 'Owner',
          sortable: true,
          searchable: true,
        },
        {
          field: 'type',
          label: 'Type',
          sortable: true,
          searchable: true,
          width: '300px'
        },
        {
          field: 'type.score',
          label: 'Score of type',
          sortable: true,
          searchable: true,
        },
        {
          field: 'tags',
          label: 'Tags',
          searchable: true,
        },
        {
          field: 'addressCount',
          label: 'number of addresses',
          searchable: true,
          sortable: true,
        },
        {
          field: 'actions',
          label: '',
          searchable: true,
        },
      ],
      riskOptions: riskOptions,
      addressNumberOptions: addressNumberOptions,
      loading: false,
      currentPage: 1,
      perPage: 10,
      totalPages: 1,
      total: 1,
      defAddrOrder: 'any',
      defaultSortOrder: 'desc',
      sortField: 'addressCount',
      sortOrder: 'desc',
      removedTag: '',
      stats: [],
    }
  },
  computed: {
    ...mapState('analytics', ['coinData']),
    isDefaultFiltersState() {
      return _.isEqual(this.formData, this.oldData)
    },
  },
  watch: {
    'formData.addresses.more.value': 'handleMoreMode',
    'formData.risk': 'handleRisk',
    activeTab: {
      handler(tabName) {
        if (tabName === 'clusters') this.loadData()
      },
      immediate: true
    },
    coinData: {
      handler() {
        this.loadData()
      },
      deep: true,
    },
  },
  created() {
    this.perPage = localStorage.getItem('clusters-stat-per-page') || this.perPage
    this.oldData = _.cloneDeep(this.formData)
    localStorage.setItem('clusters-stat-per-page', this.perPage)
  },
  methods: {
    trancateString,
    ...mapActions({
      getClustersStatisticsInfo: 'statistics/getClustersStatisticsInfo',
      deleteClusterTag: 'tagging/deleteClusterTag',
      getTypes: 'types/getTypes',
    }),
    handleScoring(score, field) {
      if (score && Number(score) > 100) {
        this.formData[field] = 100
      }
    },
    searchType(search) {
      if (!search) {this.typesOptions = [] } else {

      this.getTypes({ search }).then(({ data }) => {
        this.typesOptions = data.typeData
      })
      }
    },
    handleMoreMode(val) {
      if (val && val === this.defAddrOrder) this.formData.addresses.size = null
      if (this.isDefaultFiltersState) this.loadData(true)
    },
    handleRisk({ value }) {
      if (value && value === 'all') this.loadData(true)
    },
    toComaSeparate,
    toNumber,
    countChange() {
      localStorage.setItem('clusters-stat-per-page', this.perPage)
      this.loadData(true)
    },
    removeTag(tag, assignAddress) {
      this.removedTag = {...tag, assignAddress }
      this.showDeleteModal = true
    },
    deleteTag(tag) {
      if (tag !== undefined) {
        this.deleteClusterTag({ name: tag, address: this.removedTag.assignAddress }).then(() => {
          this.showDeleteModal = false
          this.applyFilters(false)
          this.removedTag = ''
        })
      }
    },
    async loadData(needTotal = true) {
      this.loading = true
      const sendModelData = this.formattedSendData(needTotal)
      const { data: { totalItems, result } } = await this.getClustersStatisticsInfo(sendModelData)
      this.stats = result.map((item) => ({
        ...item,
        type: item.type || []
      }))
      this.total = needTotal ? totalItems : this.total
      this.totalPages = Math.ceil(this.total / this.perPage)
      this.loading = false
    },
    resetFilters() {
      this.formData = _.cloneDeep(this.oldData)
      this.applyFilters(true)
    },
    onSort(field, order) {
      this.sortOrder = order
      this.sortField = field
      this.loadData(false)
    },
    formattedSendData(needTotal = true) {
      const addressesData = () => {
        if (this.formData.addresses.more.value !== this.defAddrOrder && !this.formData.addresses.size) {
          return null
        } else {
          return {
            more: this.formData.addresses.more.value || undefined,
            size: this.formData.addresses.size ? toNumber(this.formData.addresses.size) : undefined
          }
        }
      }

      if (needTotal) this.currentPage = 1

      this.perPage = localStorage.getItem('clusters-stat-per-page') || this.perPage

      return {
        entity: this.formData.entity || undefined,
        type: this.formData.type && this.formData.type.name
          ? this.formData.type.name
          : undefined,
        from: this.formData.from || undefined,
        to: this.formData.to || undefined,
        tag: this.formData.tag || undefined,
        owner: this.formData.owner || undefined,
        risk: this.formData.risk.value,
        addresses: addressesData(),
        sortField: this.sortField || undefined,
        count: this.perPage,
        skip: (this.currentPage - 1) * this.perPage,
        sortOrder: this.sortOrder || undefined
      }
    },
    pageChange(event) {
      this.currentPage = event
      this.loadData(false)
    },
    applyFilters(needTotal = true) {
      this.loadData(needTotal)
    },
    clearSize() {
      this.formData.addresses.size = null
    },
    clearTag() {
      this.formData.tag = null
    },
    clearEntity() {
      this.formData.owner = ''
    },
    clearType() {
      this.formData.type = ''
    },
    async copy(value) {
      await navigator.clipboard.writeText(value).then(() => {
        this.$toasted.global.success({ message: 'Copied!' })
      })
    },
  }
}
</script>

<style>
.change-page {
  border: 1px solid var(--dark-grey-d-3);
  border-radius: 3px;
  height: 35px;
  padding-top: 5px;
}

.stat-select {
  width: 100%;
  min-width: 150px;
}

.stat-select__address {
  width: 100% !important;
  min-width: 150px;
}

.stat-select__address .vs__dropdown-toggle {
  height: 40px !important;
  background-color: var(--pale-grey) !important;
  text-transform: capitalize;
  width: 100%;
  min-width: 150px;
  border: none;
}

.statistic-table .o-table .o-table__th {
  font-size: 12px;
}

.stats-custom-table .o-table .o-table__th {
  padding: 10px !important;
}

.filter-item__label {
  position: absolute;
  top: -17px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  color: var(--dark-grey-6-e);
}

.table-wrap-stat {
  box-shadow: none;
  border-radius: unset;
  border-top: 1px solid #dbdbdb;
}

::placeholder {
  color: var(--dark-grey-6-e) !important;
}
</style>
